































import { Component, Ref, Vue } from 'vue-property-decorator'
import { getActorData, resetActor } from '@/customer-portal/actor/services/actor-rest'
import { SfForm } from '@starface/sf-components/types/components'
import { RestResult } from '@/services'

@Component({})
export default class ActorSearch extends Vue {
  
  @Ref()
  public readonly searchForm!: SfForm

  private actorData = ''

  private awaitRestResponse = false

  private getActorType(): string {
    const searchData = this.searchForm.getData() as any
    return searchData.actorType.value
  }
  
  private getActorId(): string {
    const searchData = this.searchForm.getData() as any
    if (searchData.actorId && searchData.pbxId) {
      return searchData.actorId + '@' + searchData.pbxId
    }
    if (searchData.pbxId) {
      return searchData.pbxId
    }
    return searchData.actorId
  }

  public getActorData(): void {
    this.actorData = ''
    this.awaitRestResponse = true
    getActorData(this.getActorType(), this.getActorId())
        .then((result: RestResult<string>) => {
          this.awaitRestResponse = false
          this.actorData = result.data
        })
        .catch((e) => {
          console.error(e)
          this.awaitRestResponse = false
          this.$toast.error('actor.toast.errorGetData')
        })
  }

  public resetActor(): void {
    this.actorData = ''
    this.awaitRestResponse = true
    resetActor(this.getActorType(), this.getActorId())
        .then(() => this.awaitRestResponse = false)
        .catch((e) => {
          console.error(e)
          this.awaitRestResponse = false
          this.$toast.error('actor.toast.errorSetData')
        })
  }

}
